import React, { useEffect } from "react"
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Button,
  InputNumber,
  AutoComplete,
  message,
  Tag,
} from "antd"
import { isEmpty, isEqual } from "lodash"
import { DatePicker, SupplySelect, TaskForm, Upload, UserSelect } from ".."
import { useAuth, useTask } from "~/hooks"
import CustomEditor from "../Editor"
import { PRODUCT_UNIT } from "~/constants"
import { ArrowRightOutlined, MinusCircleOutlined } from "@ant-design/icons"
import { last } from "lodash"
import dayjs from "dayjs"

const StageTaskForm = ({
  employeeMode = false,
  loading,
  initialValues,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const isInCharge =
    (user?.role === "employee" && user?.id === initialValues?.assignedTo?.value) ||
    user?.role !== "employee"
  const [form] = Form.useForm()
  const watchStageTaskSupplies = Form.useWatch("stageTaskSupplies", form)

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { stageTaskSupplies, assignedTo, task, ...rest } = values

    onSubmit({
      ...rest,
      assignedToId: assignedTo?.value,
      stageTaskSupplies: stageTaskSupplies?.map((tS) => ({
        supplyId: tS.supply?.value,
        quantity: tS.quantity,
        unit: tS.unit,
        price: tS.price,
        note: tS.note,
      })),
    })
  }

  const {
    show: showTaskDrawer,
    hide: hideTaskDrawer,
    TaskDrawer,
  } = useTask({
    task: {
      ...initialValues?.task,
      startedAt: initialValues?.startedAt && dayjs(initialValues?.startedAt),
      endedAt: initialValues?.endedAt && dayjs(initialValues?.endedAt),
      taskSupplies: initialValues?.task?.taskSupplies?.map((taskSupply) => ({
        ...taskSupply,
        supply: {
          label: taskSupply?.supply?.name,
          value: taskSupply?.supply?.id,
        },
      })),
    },
    readOnly: true,
  })

  const handleOnSupplySelect = (key, supply) => {
    console.log(key, supply)
    if (supply?.supplyStockCards?.length > 0) {
      form.setFieldValue(["stageTaskSupplies", key, "price"], supply?.supplyStockCards[0]?.price)
    }
  }

  const isMultipleDaysTask = (task) => {
    if (!task?.startedAt || !task?.endedAt) {
      return false
    }

    return dayjs(task.startedAt).diff(dayjs(task.endedAt), "day") != 0
  }

  return (
    <div>
      <Form
        layout="vertical"
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                if (employeeMode) {
                  return (
                    <Form.Item label={"Công việc"} name={["task", "name"]}>
                      <Input disabled size={"large"} />
                    </Form.Item>
                  )
                }

                return (
                  <Form.Item label={"Công việc"} name={["task", "name"]}>
                    <Button onClick={showTaskDrawer} type="link">
                      {getFieldValue("task")?.name}
                    </Button>
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                if (employeeMode) {
                  return (
                    <Form.Item label={"Mô tả thêm (nếu có)"} name={["task", "description"]}>
                      <Input disabled size={"large"} />
                    </Form.Item>
                  )
                }

                return (
                  <Form.Item label={"Mô tả thêm (nếu có)"} name={["task", "description"]}>
                    <span className="font-medium">{getFieldValue("task")?.description}</span>
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Người xử lý"} name="assignedTo">
              <UserSelect {...(employeeMode && { size: "large", disabled: true })} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label={"Ngày thực hiện"} name="startedAt">
              <DatePicker
                disabled={employeeMode && !isInCharge}
                className="w-full"
                {...(employeeMode && { size: "large" })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item label={"Khung giờ thực hiện"}>
              <div>
                {initialValues?.startedAt ? dayjs(initialValues?.startedAt).format("HH:mm") : "--"}{" "}
                <ArrowRightOutlined />{" "}
                {initialValues?.endedAt ? dayjs(initialValues?.endedAt).format("HH:mm") : "--"}
              </div>
            </Form.Item>
          </Col>

          {isMultipleDaysTask(initialValues?.task) && (
            <Col xs={24} lg={24} className="mb-3">
              <span className="text-red-500">
                * Công việc thực hiện liên tục từ{" "}
                <span className="font-semibold">
                  {dayjs(initialValues?.task?.startedAt).format("DD/MM/YYYY")}
                </span>{" "}
                đến{" "}
                <span className="font-semibold">
                  {dayjs(initialValues?.task?.endedAt).format("DD/MM/YYYY")}
                </span>
              </span>
            </Col>
          )}

          <Col xs={24} lg={24}>
            <Form.Item label={"Xác nhận hoàn tất vào lúc"} name="completedAt">
              <DatePicker
                disabled={employeeMode && !isInCharge}
                className="w-full"
                {...(employeeMode && { size: "large" })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Ghi chú của người thực hiện (Nếu có)"} name="note">
              <CustomEditor disabled={employeeMode && !isInCharge} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Vật tư sử dụng"}>
              <div>
                <Form.List name="stageTaskSupplies">
                  {(fields, { add, remove }) => (
                    <div
                      className={`${fields.length > 0 ? (employeeMode ? "p-2" : "border p-4 shadow-lg") : ""}`}
                    >
                      {fields.map(({ key, name, ...restFields }, index) => (
                        <Row key={key} gutter={[16, 8]} className="mb-[16px] w-full">
                          {index > 0 && (
                            <Col span={24}>
                              <Divider />
                            </Col>
                          )}
                          <Col xs={24} lg={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Sản phẩm vật tư ${name + 1}`}
                              name={[name, "supply"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng chọn sản phẩm vật tư",
                                },
                              ]}
                            >
                              <SupplySelect
                                disabled={employeeMode && !isInCharge}
                                allowClear={false}
                                onChange={(_, supply) => handleOnSupplySelect(name, supply)}
                              />
                            </Form.Item>

                            <Form.Item
                              {...restFields}
                              label={`Đơn giá (tại thời điểm nhập hàng cuối cùng)`}
                              name={[name, "price"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập đơn giá",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                formatter={(value) =>
                                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                className="w-full"
                                placeholder="Đơn giá"
                                disabled={employeeMode && !isInCharge}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12} xl={8}>
                            <Form.Item
                              {...restFields}
                              label={`Số lượng sử dụng`}
                              name={[name, "quantity"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Vui lòng nhập số lượng sử dụng",
                                },
                              ]}
                            >
                              <InputNumber
                                min={0}
                                formatter={(value) =>
                                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                className="w-full"
                                placeholder="Liều lượng sử dụng"
                                disabled={employeeMode && !isInCharge}
                              />
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                              {({ getFieldValue }) => (
                                <Form.Item label={"Tổng tiền tạm tính"}>
                                  <b>
                                    {(
                                      (getFieldValue(["stageTaskSupplies", name, "price"]) || 0) *
                                      (getFieldValue(["stageTaskSupplies", name, "quantity"]) || 0)
                                    )
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                    đ
                                  </b>
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={23} xl={7}>
                            <Form.Item {...restFields} label={`Ghi chú`} name={[name, "note"]}>
                              <Input.TextArea
                                disabled={employeeMode && !isInCharge}
                                placeholder="Ghi chú"
                                rows={5}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1} className="flex items-center">
                            <MinusCircleOutlined
                              className="cursor-pointer"
                              onClick={() => !(employeeMode && !isInCharge) && remove(name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Button
                        onClick={() => {
                          if (
                            last(watchStageTaskSupplies)?.supply ||
                            !watchStageTaskSupplies ||
                            watchStageTaskSupplies?.length === 0
                          ) {
                            add()
                          }
                        }}
                        disabled={employeeMode && !isInCharge}
                      >
                        Thêm vật tư nông nghiệp
                      </Button>
                    </div>
                  )}
                </Form.List>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh thêm"} name="imageUrls">
              <Upload disabled={employeeMode && !isInCharge} multiple />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          {!employeeMode && (
            <div>
              <Button loading={loading} type={"primary"} htmlType={"submit"}>
                Lưu
              </Button>
              <Button
                onClick={onCancel}
                disabled={loading}
                type={"outline"}
                style={{ marginLeft: 16 }}
              >
                Hủy
              </Button>
            </div>
          )}
          {employeeMode && (
            <Button
              disabled={!isInCharge}
              type="primary"
              size="large"
              block
              loading={loading}
              htmlType={"submit"}
            >
              Cập nhật
            </Button>
          )}
        </Row>
      </Form>
      <TaskDrawer />
    </div>
  )
}

export default StageTaskForm
